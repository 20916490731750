


















import { Component, Prop, Vue, ProvideReactive } from 'vue-property-decorator';
import FlowGraph from '@/components/flow-design/flow-graph.vue';
import RiverHeader from '../layout/components/river-header/index.vue';

import FormConfig from '@/components/form-design/config/form-config.vue';

import eventBus from '@/eventBus/index';

@Component({
  name: 'FlowDesigner',
  components: {
    FlowGraph,
    RiverHeader,
    FormDesigner: () => import('../form-design/form-designer.vue'),
    FormConfig
  }
})
export default class Flow extends Vue {
  formConfigDiglogVisible: boolean = false;
  appId: string = '';
  showFlow: boolean = true;
  showForm: boolean = false;

  @ProvideReactive() formId: any = null;

  @Prop()
  private flowId?: string;
  get _flowId() {
    return this.$route.query.id || this.flowId;
  }

  get _flowName() {
    if (this.$store.getters.flowConfig) {
      return this.$store.getters.flowConfig.displayName;
    }
    return '';
  }

  getAllData() {}

  changeShowForm() {
    if (!sessionStorage.getItem('pFormId')) {
      this.formConfigDiglogVisible = true;
      return;
    }
    this.showFlow = false;
    this.showForm = true;
  }
  changeShowFlow() {
    if (this.showFlow) return;
    this.showForm = false;
    this.showFlow = true;
  }

  created() {
    this.appId = this.$route.query.applicationId as string;
  }
  mounted() {
    if (sessionStorage.getItem('loadForm')) {
      this.showFlow = false;
      sessionStorage.removeItem('loadForm');
      setTimeout(() => {
        this.showForm = true;
      }, 600);
    }
    eventBus.$on('changeShow', data => {
      if (data == 'flow') {
        this.changeShowFlow();
      }
      if (data == 'form') {
        this.changeShowForm();
      }
    });
  }
  beforeDestroy() {
    sessionStorage.removeItem('pFormId');
    sessionStorage.removeItem('loadForm');
    sessionStorage.removeItem('connectData');
  }
}
