var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "wrap-container", attrs: { direction: "vertical" } },
    [
      _vm.showFlow
        ? [
            _c("river-header", [
              _c("span", { staticClass: "flow-title" }, [
                _vm._v(_vm._s(_vm._flowName)),
              ]),
            ]),
            _c("el-main", { staticClass: "wrap-main" }, [
              _c(
                "div",
                { staticClass: "form-designer-container" },
                [
                  _c("flow-graph", {
                    ref: "flowGrapgRef",
                    attrs: { flowId: _vm._flowId },
                  }),
                ],
                1
              ),
            ]),
          ]
        : _vm._e(),
      _vm.showForm ? _c("FormDesigner") : _vm._e(),
      _c("form-config", {
        attrs: {
          connectForm: true,
          applicationId: _vm.appId,
          visible: _vm.formConfigDiglogVisible,
        },
        on: {
          "update:visible": function ($event) {
            _vm.formConfigDiglogVisible = $event
          },
          success: _vm.getAllData,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }